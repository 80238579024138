import { Link } from "gatsby";
import React from "react";

const Footer = props => (
	<footer className="footer">
        <div className="container">
          <span>© {new Date().getFullYear()} cocowat</span>
		
			<ul>
				<li><Link to="/aviso-legal">Aviso legal</Link></li>
				<li><Link to="/politica-privacidad">Política de privacidad</Link></li>
				<li><Link to="/politica-cookies">Política de cookies</Link></li>
			</ul>
        </div>
	</footer>
)

export default Footer
